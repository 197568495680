html {
  color: #fff;
  overscroll-behavior: contain;
  color-scheme: dark;
  background: #000;
}

body {
  margin: 0;
  font-family: monospace;
}

input {
  height: 1.65rem;
  min-height: 1.65rem;
  margin-right: .5rem;
}

button {
  border: 1px solid;
  min-width: 2rem;
  height: 2rem;
  min-height: 2rem;
  overflow: hidden;
}

.main-content {
  padding: .35rem;
}

.current-match {
  justify-content: center;
  font-size: 1.5rem;
  display: flex;
}

.vs-button {
  width: 8.25rem;
  max-width: 8.25rem;
  height: 4rem;
  min-height: 4rem;
  font-size: 1.75rem;
}

.next-match {
  justify-content: center;
  font-size: 1.5rem;
  display: flex;
}

.vs-button-next {
  width: 6.5rem;
  max-width: 6.5rem;
  height: 3rem;
  min-height: 3rem;
  font-size: 1.5rem;
}

.corner {
  width: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  min-height: 2.5rem;
}

.top-header {
  flex-shrink: 0;
  width: 4rem;
  height: 2.5rem;
  display: inline-block;
}

.left-header {
  flex-shrink: 0;
  width: 2.5rem;
  height: 4rem;
  display: inline-block;
}

.grid-row {
  flex-wrap: nowrap;
  display: flex;
}

.grid-button {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
}

.button-disabled-person-background {
  color: #708090;
  background: #000;
  text-decoration: line-through;
}

.button-selected-person-border {
  z-index: 0;
  border: 1px solid gold;
  box-shadow: 0 0 8px 2px gold;
}

.button-selected-match-border {
  z-index: 1;
  border: 1px solid red;
  box-shadow: 0 0 8px 2px red;
}

.grid-button-finished {
  color: #708090;
  background: #000;
}

.loser {
  color: tomato;
}

.winner {
  color: #00ff7f;
}

.undo-container {
  justify-content: space-between;
  display: flex;
}

.toggle-enable-button {
  color: #000;
  background: #00ff7f;
  width: 4rem;
  max-width: 4rem;
}

.toggle-disable-button {
  color: #000;
  background: tomato;
  width: 4rem;
  max-width: 4rem;
}

.top-1 {
  text-shadow: 0 0 4px gold, 0 0 4px gold, 0 0 4px gold;
}

.top-2 {
  text-shadow: 0 0 4px silver, 0 0 4px silver, 0 0 4px silver;
}

.top-3 {
  text-shadow: 0 0 4px #d2691e, 0 0 4px #d2691e, 0 0 4px #d2691e;
}
/*# sourceMappingURL=index.94ef7e0a.css.map */
