html {
  background: black;
  color: white;
  overscroll-behavior: contain;
  color-scheme: dark;
}

body {
  margin: 0;
  font-family: monospace;
}

input {
  min-height: 1.65rem;
  height: 1.65rem;
  margin-right: 0.5rem;
}

button {
  min-width: 2rem;
  min-height: 2rem;
  height: 2rem;
  overflow: hidden;
  border: solid 1px;
}

.main-content {
  padding: 0.35rem;
}

.current-match {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.vs-button {
  max-width: 8.25rem;
  width: 8.25rem;
  min-height: 4rem;
  height: 4rem;
  font-size: 1.75rem;
}

.next-match {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
}

.vs-button-next {
  max-width: 6.5rem;
  width: 6.5rem;
  min-height: 3rem;
  height: 3rem;
  font-size: 1.5rem;
}

.corner {
  min-width: 2.5rem;
  min-height: 2.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.top-header {
  display: inline-block;
  flex-shrink: 0;
  width: 4rem;
  height: 2.5rem;
}

.left-header {
  display: inline-block;
  flex-shrink: 0;
  width: 2.5rem;
  height: 4rem;
}

.grid-row {
  display: flex;
  flex-wrap: nowrap;
}

.grid-button {
  flex-shrink: 0;
  width: 4rem;
  height: 4rem;
}

.button-disabled-person-background {
  background: black;
  text-decoration: line-through;
  color: slategray;
}

.button-selected-person-border {
  z-index: 0;
  border: solid gold 1px;
  box-shadow: 0px 0px 8px 2px gold;
}

.button-selected-match-border {
  z-index: 1;
  border: solid red 1px;
  box-shadow: 0px 0px 8px 2px red;
}

.grid-button-finished {
  background: black;
  color: slategray;
}

.loser {
  color: tomato;
}

.winner {
  color: springgreen;
}

.undo-container {
  display: flex;
  justify-content: space-between;
}

.toggle-enable-button {
  max-width: 4rem;
  width: 4rem;
  background: springgreen;
  color: black;
}

.toggle-disable-button {
  max-width: 4rem;
  width: 4rem;
  background: tomato;
  color: black;
}

.top-1 {
  text-shadow:
    0 0 4px gold,
    0 0 4px gold,
    0 0 4px gold;
}

.top-2 {
  text-shadow:
    0 0 4px silver,
    0 0 4px silver,
    0 0 4px silver;
}

.top-3 {
  text-shadow:
    0 0 4px chocolate,
    0 0 4px chocolate,
    0 0 4px chocolate;
}
